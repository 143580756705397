import {
  ACCOUNT_TYPE_TOOL,
  AUCTION_REQUEST_DECLINED_REASON_NO_CAPACITY,
  AUCTION_STATUS_PAUSED,
  ONBOARDING_EMAIL_PRIO,
  ONBOARDING_EMAIL_REGULAR,
  ONBOARDING_EMAIL_REMINDER,
  ORIGIN_RECEIVER,
  ORIGIN_SENDER,
  ORIGIN_TOOLS,
  PATIENT_ELIGIBILITY_APPROVED,
  PATIENT_ELIGIBILITY_IN_PROCESS,
  PATIENT_ELIGIBILITY_REJECTED,
  PATIENT_ELIGIBILITY_TO_BE_PROCESSED,
  PATIENT_FORM_ALREADY_SHARED,
  PATIENT_FORM_DRAFT,
  PATIENT_FORM_READY,
  REHAB_FORM_TYPES,
  REQUEST_UNAVAILABLE_REASON_ACCEPTED,
  REQUEST_UNAVAILABLE_REASON_STOPPED,
  REQUEST_UNAVAILABLE_REASON_SUCCESS,
} from "core/consts";
import { EVENTS } from "core/consts/events";
import { getName, getProviderSearchAccountName } from "core/model/accounts";
import { auctionSuccessEvent, declineDetailsReason } from "core/model/auctions";
import { getAuctionStopReason } from "core/model/auctions/stopReasons";
import {
  getBouncedEmailsTranslations,
  getPatientUpdatedFields,
  getRejectedProviderFilterReason,
} from "core/model/requests";
import {
  ONTOLOGY_SOLUTION,
  ONTOLOGY_SPECIALIZATIONS,
} from "core/model/utils/ontologies";
import { grammaticalList } from "core/model/utils/strings";
import {
  AcceptedSolution,
  Account,
  AuctionRequest,
  Event,
  EventLocation,
  EventType,
  Filev1,
  GetOntologyType,
} from "core/types";
import {
  ArchiveIcon,
  AssignmentIcon,
  AttachFile,
  BlockIcon,
  CachedIcon,
  CheckIcon,
  CloseIcon,
  DeleteIcon,
  DoneIcon,
  EditIcon,
  ErrorIcon,
  IconType,
  LockIcon,
  MessageIcon,
  NotInterestedIcon,
  PauseIcon,
  PersonIcon,
  PlaylistAddCheckIcon,
  PrintIcon,
  PriorityHighIcon,
  SendIcon,
  SmsIcon,
  StarBorderIcon,
  StarIcon,
  UndoIcon,
} from "ds/icons";
import {
  getAcceptedMessages,
  getLegacyAcceptedMessages,
  getMessage,
  isEncrypted,
  whoIsSender,
  whoTookAction,
} from "dsl/atoms/EventSpecs/utils";
import { ERROR_ICON } from "dsl/molecules/Messenger/EventCard";
import { LinkProps } from "react-router-dom";
import { Locale as LocaleString } from "translations";
import { getTranslationForAccount } from "translations/helpers";
import Translations from "translations/types";

type GetEventSpecsProps = {
  accountId?: Account["id"] | null;
  allContactedTranslation: string;
  auctionRequest?: AuctionRequest;
  auctionSuccessUnknownTranslation: string;
  currentLocale: Locale;
  event: Event;
  forIsik?: boolean;
  getOntology: GetOntologyType;
  locale: LocaleString;
  location: EventLocation;
  loggedCareproviderId: number | undefined;
  loggedCareseekerId: number | undefined;
  notNeededTranslation: string;
  patientId?: string;
  sharePatientDataAutomatically?: boolean;
  translations: Translations;
};

export type EventSpecMessage = {
  date?: string;
  link?: {
    label: string;
    to: LinkProps["to"];
  };
  reasons?: string[];
  time?: string;
  title: string | null;
};

export type EventSpecs = {
  acceptedSolutionsLabel?: string;
  eventMessages?: EventSpecMessage[];
  icon?: IconType;
  message?: string | null;
  messages?: string[] | null;
  receiverTitle?: string | null | undefined;
  recieverIcon?: IconType;
  sentByLabel?: string | null | undefined;
  shouldBlur?: boolean;
  shouldHide?: EventLocation[];
  title: string;
  variant?: "error" | "success" | "warning";
};

const safeLogEvent = (event: Event) =>
  JSON.stringify(
    {
      ...event,
      context: {
        ...event.context,
        message: "N/A",
        file: {
          ...event.context?.file,
          encrypted_name: {
            ...event.context?.file?.encrypted_name,
            decrypted: "N/A",
          },
        },
      },
    } as Event,
    null,
    2,
  );

function getFileLink({
  translations,
  ...eventMessage
}: Partial<EventSpecMessage> & {
  translations: Translations;
}): EventSpecMessage {
  const searchParams = new URLSearchParams(window.location.search);
  searchParams.set("activeTab", "files");

  const result: EventSpecMessage = {
    title: null,
    ...eventMessage,
    link: {
      to: { search: searchParams.toString() },
      label: translations.fileSection.seeDocument,
    },
  };

  return result;
}

const getFileNameConfig = (
  file: Filev1 | null | undefined,
  translations: Translations,
): Partial<EventSpecs> => {
  if (!file) {
    return { eventMessages: undefined };
  }

  const { encrypted_name, name, title } = file;

  // Retro compatibility w/ previous files
  if (encrypted_name == null)
    return {
      eventMessages: [getFileLink({ title: `${title} ${name}`, translations })],
    };

  if (
    !encrypted_name?.content ||
    !encrypted_name?.iv ||
    !encrypted_name?.decrypted
  )
    return {
      eventMessages: [
        getFileLink({
          title: translations.auctionRequest.encryptedMessage,
          translations,
        }),
      ],
      shouldBlur: true,
    };

  return {
    eventMessages: [
      getFileLink({ title: encrypted_name.decrypted, translations }),
    ],
  };
};

const shouldUseAcceptedSolutionEventSpecs = (
  acceptedSolutions: AcceptedSolution[] | undefined,
) => {
  const acceptedSolutionsLength = acceptedSolutions?.length ?? 0;
  const inCompleteData =
    acceptedSolutionsLength &&
    acceptedSolutions?.some(
      (acceptedSolution: AcceptedSolution) => !acceptedSolution?.solution,
    );
  if (acceptedSolutionsLength > 1 && inCompleteData) {
    throw new Error(
      `Accepted solutions returned with incomplete data ${JSON.stringify(
        acceptedSolutions,
        null,
        2,
      )}`,
    );
  }
  return acceptedSolutionsLength && !inCompleteData;
};

function getFileName(event: Event, translations: Translations) {
  switch (event.context?.file_type) {
    case REHAB_FORM_TYPES.GENERAL:
      return translations.insuranceApp.eligibilityTab.requestFormName;
    case REHAB_FORM_TYPES.MEDICAL:
      return translations.insuranceApp.eligibilityTab.medicalFormName;
    default:
      return "";
  }
}

function getOnboardingEmailType(event: Event, translations: Translations) {
  switch (event?.context?.onboarding_email_type) {
    case ONBOARDING_EMAIL_REGULAR:
      return translations.timeline.onboardingEmail.regular;
    case ONBOARDING_EMAIL_PRIO:
      return translations.timeline.onboardingEmail.prio;
    case ONBOARDING_EMAIL_REMINDER:
      return translations.timeline.onboardingEmail.reminder;
    default:
      return translations.timeline.onboardingEmail.notSpecified;
  }
}

function getPatientWishReasonMessage(event: Event, translations: Translations) {
  if (!event.context?.message)
    return {
      title:
        translations.timeline.addedPatientWishReason.deletedPatientWishReason,
    };

  return {
    title: event.context?.message,
  };
}

function getRequestAcceptedSpecs({
  encrypted,
  event,
  getOntology,
  locale,
  modularAccountName,
  providerName,
  translations,
}: Pick<
  GetEventSpecsProps,
  "event" | "getOntology" | "locale" | "translations"
> & {
  encrypted: boolean;
  modularAccountName: string;
  providerName: string;
}): EventSpecs {
  const acceptedEventProps: Partial<EventSpecs> = {
    icon: CheckIcon,
    variant: "success",
    recieverIcon: CheckIcon,
  };
  const acceptedSolutions = event.context?.accepted_solutions;
  if (shouldUseAcceptedSolutionEventSpecs(acceptedSolutions)) {
    return {
      ...acceptedEventProps,
      title: translations.responseEvents.acceptedMultiSolution.otherAccount({
        providerName,
      }),
      acceptedSolutionsLabel: grammaticalList({
        array: event.context?.accepted_solutions?.map(
          ({ solution }) => solution,
        ),
        ontologyType: "solution",
        getOntology,
        translations,
      }),
      receiverTitle:
        translations.responseEvents.acceptedMultiSolution.myAccount,
      ...getAcceptedMessages({
        event,
        encrypted,
        getOntology,
        locale,
        translations,
      }),
    };
  }
  // LEGACY EVENT CONTEXT
  return {
    ...acceptedEventProps,
    title: translations.requestEvents.providerAcceptedTheRequest({
      providerName,
    }),
    receiverTitle: getTranslationForAccount(
      translations.responseEvents.acceptedTheRequest,
      modularAccountName,
    ),
    ...getLegacyAcceptedMessages({
      event,
      encrypted,
      locale,
      translations,
    }),
  };
}

function getRequestUnavailableOrRejected({
  encrypted,
  event,
  getOntology,
  location,
  modularAccountName,
  notNeededTranslation,
  providerName,
  translations,
  type,
}: Pick<
  GetEventSpecsProps,
  "event" | "getOntology" | "location" | "translations"
> & {
  encrypted: boolean;
  modularAccountName: string;
  notNeededTranslation: string;
  providerName: string;
  type: Event["type"];
}): EventSpecs {
  const partial: Partial<EventSpecs> = {
    icon: type === EVENTS.REQUEST_UNAVAILABLE ? BlockIcon : ERROR_ICON,
    variant: "error",
    receiverTitle: translations.timeline.requestUnavailableProvider,
  };

  if (event?.context?.solution_filter_reasons) {
    const filterReasons = Object.values(event.context.solution_filter_reasons)
      .flatMap((filters) => {
        return getRejectedProviderFilterReason(
          filters,
          translations,
          getOntology,
        );
      })
      .truthy();

    const deduplicated = filterReasons.filter(
      (warningMessage: string, i: number) =>
        filterReasons.indexOf(warningMessage) === i,
    );

    return {
      ...partial,
      title: `${
        translations.requestEvents.updatedPatientRuledOut
      } ${grammaticalList({ array: deduplicated, translations })}`,
      shouldHide: ["documentation"],
    };
  } else if (event?.context?.filters) {
    const ruledOutReasons = event.context?.filters
      ? getRejectedProviderFilterReason(
          event.context.filters,
          translations,
          getOntology,
        )
      : "";

    return {
      ...partial,
      title: `${translations.requestEvents.updatedPatientRuledOut} ${ruledOutReasons}`,
      shouldHide: ["documentation"],
    };
  }

  if (
    event.context &&
    event.context.request_unavailable_reason ==
      REQUEST_UNAVAILABLE_REASON_SUCCESS
  ) {
    return {
      ...partial,
      title: translations.timeline.anotherProviderSelected,
      shouldHide: ["documentation"],
    };
  }
  if (
    location !== "documentation" &&
    event.type === EVENTS.RESPONSE_REJECTED &&
    event.context?.request_unavailable_reason ==
      REQUEST_UNAVAILABLE_REASON_STOPPED
  ) {
    return {
      ...partial,
      title:
        getAuctionStopReason(
          event,
          translations,
          modularAccountName,
          notNeededTranslation,
        ) || NO_TITLE,
    };
  }

  if (location === "documentation" && event.type === EVENTS.RESPONSE_REJECTED) {
    const partialTitle = getTranslationForAccount(
      translations.requestEvents.rejectedThisProvider,
      modularAccountName,
      providerName,
    );

    const internalRemarks = event?.context?.clinic_internal_remarks
      ? `${translations.general.colon} ${event.context.clinic_internal_remarks}`
      : "";

    return {
      ...partial,
      receiverTitle: translations.timeline.requestUnavailableProvider,
      title: `${partialTitle}${internalRemarks}`,
      message: getMessage(event, encrypted, translations),
      shouldHide: event.context?.filters ? ["documentation"] : undefined,
    };
  }
  if (
    event.type === EVENTS.REQUEST_UNAVAILABLE &&
    event?.context?.request_unavailable_reason ==
      REQUEST_UNAVAILABLE_REASON_ACCEPTED
  ) {
    return {
      ...partial,
      receiverTitle:
        translations.auctionResponse.requestReservedAnotherProvider,
      title: translations.auctionResponse.requestReservedAnotherProvider,
    };
  }

  return {
    ...partial,
    receiverTitle: translations.timeline.requestUnavailableProvider,
    title: getTranslationForAccount(
      translations.requestEvents.rejectedThisProvider,
      modularAccountName,
      providerName,
    ),
    message: getMessage(event, encrypted, translations),
  };
}

/**
 * a) sender shares a file
 * 2 file_shared created
 *  for origin 5 careseeker Obj careprovider null
 *  for origin 5 careseeker null careprovider Obj
 * aa) if you are careseeker you don't want to see
 *  for origin 5 careseeker Obj careprovider null (you sharing with yourself) [loggedCareseeker && origin 5 && careseekerObj]
 * ab) if you are careprovider you don't want to see
 *  for origin 5 careseeker Obj careprovider null (them sharing with themselves) [loggedCareprovider && origin 5 && careseekerObj]
 *
 *
 * b) receiver shares a file
 * 2 file_shared created
 *  for origin 2 careseeker null careprovider Obj
 *  for origin 2 careseeker Obj careprovider null
 * ba) if you are careseeker you don't want to see
 *  for origin 2 careseeker null careprovider Obj (them sharing with themselves) [loggedCareseeker && origin 2 && careproviderObj]
 * bb) if you are careprovider you don't want to see
 *  for origin 2 careseeker null careprovider Obj (you sharing with yourself) [loggedCareprovider && origin 2 && careproviderObj]
 *
 */
function dismissSealdAccessEvent({
  event,
  loggedCareproviderId,
  loggedCareseekerId,
}: {
  event: Event;
  loggedCareproviderId: number | undefined;
  loggedCareseekerId: number | undefined;
}) {
  if (
    (loggedCareseekerId &&
      event.origin === ORIGIN_SENDER &&
      event.careseeker?.id) ||
    (loggedCareproviderId &&
      event.origin === ORIGIN_SENDER &&
      event.careseeker?.id) ||
    (loggedCareseekerId &&
      event.origin === ORIGIN_RECEIVER &&
      event.careprovider?.id) ||
    (loggedCareproviderId &&
      event.origin === ORIGIN_RECEIVER &&
      event.careprovider?.id)
  ) {
    return true;
  }
  return false;
}

const NO_TITLE = "NO_TITLE" as const;
const NO_EVENT = "NO EVENT" as const;

export function getEventSpecs({
  accountId,
  allContactedTranslation,
  auctionSuccessUnknownTranslation,
  currentLocale,
  event,
  forIsik,
  getOntology,
  locale,
  location,
  loggedCareproviderId,
  loggedCareseekerId,
  notNeededTranslation,
  sharePatientDataAutomatically,
  translations,
}: GetEventSpecsProps): EventSpecs | undefined {
  const accountName = getName(event.account, getOntology, {
    providerSearchAccountName: getProviderSearchAccountName({
      translations,
      patientId: event?.context?.user_id,
    }),
  });
  const encrypted = isEncrypted(event);
  const providerName =
    event.careprovider?.name ?? event?.context?.careprovider_name ?? "";
  const newAssigneeName = event?.context?.new_assignee_name ?? "";
  const oldAssigneeName = event?.context?.old_assignee_name ?? "";
  const modularAccountName =
    whoTookAction(accountId, event, accountName, !!forIsik) ?? "";
  const isInternalToolAccount =
    event?.account?.account_type === ACCOUNT_TYPE_TOOL ||
    event?.origin === ORIGIN_TOOLS;

  function getSpecs(type: EventType): EventSpecs | typeof NO_EVENT | undefined {
    switch (type) {
      case EVENTS.MAIL_FAILURE: {
        return {
          title: translations.timeline.mailFailure,
          ...(event.context?.message && {
            eventMessages: [{ title: event.context.message }],
          }),
        };
      }

      case EVENTS.REQUEST_AVAILABLE: {
        return {
          title: translations.timeline.requestAvailable,
          icon: CachedIcon,
        };
      }

      case EVENTS.AUCTION_CANCELLED: {
        return {
          title:
            getAuctionStopReason(
              event,
              translations,
              modularAccountName,
              notNeededTranslation,
            ) || NO_TITLE,
          icon: ERROR_ICON,
          variant: "error",
        };
      }

      case EVENTS.VALIDATION_CANCELLED: {
        let title = "";
        let receiverTitle = null;
        if (event.context?.reason != null) {
          const reason = event.context?.reason || "";
          receiverTitle =
            translations.responseEvents.clinicCanceledAttributionWithReason({
              accountName,
              reason,
            });
          if (providerName) {
            title = translations.timeline.cancelledValidation({
              workerName: accountName,
              providerName,
              reason,
            });
          } else {
            title =
              translations.timeline.auctionValidationCanceledUnknownReason({
                accountName,
                reason,
              });
          }
        } else {
          receiverTitle = translations.responseEvents.clinicCanceledAttribution(
            { accountName },
          );
          if (providerName) {
            title = getTranslationForAccount(
              translations.requestEvents.canceledProviderValidation,
              modularAccountName,
              providerName,
            );
          } else {
            title = translations.timeline.auctionValidationCanceledUnknown({
              accountName,
            });
          }
        }

        return {
          receiverTitle,
          title,
          icon: UndoIcon,
        };
      }

      case EVENTS.REQUEST_TO_BE_SENT: {
        return {
          title: translations.timeline.requestToBeSent({
            accountName,
            careprovider: providerName,
          }),
          icon: PlaylistAddCheckIcon,
        };
      }

      case EVENTS.SUGGESTION_CANCELLED: {
        return {
          title: translations.timeline.suggestionCancelled({
            accountName,
            careprovider: providerName,
          }),
          icon: NotInterestedIcon,
        };
      }

      case EVENTS.REFUSAL_CANCELLED: {
        return {
          icon: UndoIcon,
          title: getTranslationForAccount(
            translations.requestEvents.canceledProviderRejection,
            modularAccountName,
            providerName,
          ),
        };
      }

      case EVENTS.AUCTION_ALL_CONTACTED: {
        return {
          icon: PriorityHighIcon,
          title: allContactedTranslation,
        };
      }

      case EVENTS.PATIENT_DISCHARGED: {
        return {
          title: translations.timeline.patientDischarged,
        };
      }

      case EVENTS.PATIENT_CREATED: {
        return {
          title: translations.timeline.patientCreated,
        };
      }

      case EVENTS.SYSTEM_ERROR: {
        return {
          title: "System error",
          ...(event.context?.message && {
            eventMessages: [{ title: event.context.message }],
          }),
        };
      }

      case EVENTS.REQUEST_CREATED: {
        return {
          title: getTranslationForAccount(
            translations.requestEvents.addedTheProvider,
            modularAccountName,
            providerName,
          ),
          icon: PlaylistAddCheckIcon,
        };
      }

      case EVENTS.FAX_DELIVERED: {
        return {
          title: translations.faxRequest.faxEvents.faxDelivered({
            receivername: providerName,
          }),
          icon: PrintIcon,
        };
      }

      case EVENTS.FAX_FAILED: {
        return {
          title: translations.faxRequest.faxEvents.faxBounced,
          icon: ErrorIcon,
        };
      }

      case EVENTS.FAX_SENT: {
        return {
          title: translations.faxRequest.faxEvents.faxSent({
            receivername: providerName,
          }),
          icon: SendIcon,
        };
      }

      case EVENTS.FAX_NOT_AVAILABLE: {
        return {
          title: translations.faxRequest.faxEvents.faxNumberNotAvailable({
            receivername: providerName,
          }),
          icon: BlockIcon,
        };
      }

      case EVENTS.CAREPROVIDER_ACCEPT_CANCELLED: {
        return {
          icon: UndoIcon,
          receiverTitle: getTranslationForAccount(
            translations.responseEvents.canceledAcceptance,
            modularAccountName,
          ),
          title: translations.requestEvents.canceledAcceptance({
            providerName,
          }),
        };
      }

      case EVENTS.CAREPROVIDER_DECLINE_CANCELLED: {
        return {
          icon: UndoIcon,
          receiverTitle: getTranslationForAccount(
            translations.responseEvents.canceledDeclination,
            modularAccountName,
          ),
          title: translations.requestEvents.canceledDeclination({
            providerName,
          }),
          ...(event.context?.declined_reason ===
            AUCTION_REQUEST_DECLINED_REASON_NO_CAPACITY && {
            eventMessages: [
              {
                title:
                  translations.auctionResponse.noCapacityReasons
                    .reasonCancelDeclination,
              },
            ],
          }),
        };
      }

      case EVENTS.REQUEST_MESSAGE_TO_CLINIC:
      case EVENTS.ENCRYPTED_MESSAGE:
      case EVENTS.ELIGIBILITY_MESSAGE:
      case EVENTS.REQUEST_MESSAGE_TO_PROVIDER: {
        return {
          title: getTranslationForAccount(
            translations.requestEvents.wrote,
            modularAccountName,
            providerName,
          ),
          receiverTitle: getTranslationForAccount(
            translations.requestEvents.wrote,
            modularAccountName,
            providerName,
          ),
          message: getMessage(event, encrypted, translations),
          icon: MessageIcon,
        };
      }

      case EVENTS.REQUEST_DECLINED: {
        const message = declineDetailsReason({
          getOntology,
          locale: currentLocale,
          options: event.context,
          searchType: event.context?.patient_type,
          translations,
        });

        const [eventMessageTitle, ...reasons] = message?.split("\n") || "";

        return {
          title: translations.requestEvents.providerDeclinedRequest({
            providerName,
          }),
          receiverTitle: getTranslationForAccount(
            translations.responseEvents.declinedRequest,
            modularAccountName,
          ),
          ...(eventMessageTitle && {
            eventMessages: [{ title: eventMessageTitle, reasons }],
          }),
          icon: CloseIcon,
          variant: "error",
        };
      }

      case EVENTS.REQUEST_ACCEPTED: {
        return getRequestAcceptedSpecs({
          encrypted,
          event,
          getOntology,
          locale,
          modularAccountName,
          providerName,
          translations,
        });
      }

      case EVENTS.REQUEST_SENT: {
        // If accountName === "" (internal tool), then display careseeker's name.
        const socialWorker = accountName || event?.careseeker?.name || "";

        if (event.context?.manual) {
          return {
            title: translations.timeline.requestManuallyAdded({
              careprovider: providerName,
              socialWorker,
            }),
            message: getMessage(event, encrypted, translations),
            icon: SendIcon,
          };
        }

        return {
          title: translations.requestEvents.requestSent({
            careprovider: providerName,
          }),
          receiverTitle: translations.responseEvents.requestReceived({
            socialWorker,
          }),
          icon: SendIcon,
          shouldHide: ["documentation"],
        };
      }

      case EVENTS.PATIENT_REQUEST_UPDATED: {
        const message = getPatientUpdatedFields({
          diffs: event.context?.diff,
          translations,
        });
        const [eventMessageTitle, ...reasons] = message?.split("\n") || "";

        return {
          title: getTranslationForAccount(
            translations.requestEvents.updatedPatient,
            modularAccountName,
          ),
          ...(eventMessageTitle && {
            eventMessages: [{ title: eventMessageTitle, reasons }],
          }),
          icon: EditIcon,
        };
      }

      case EVENTS.PATIENT_UPDATED:
      case EVENTS.AUCTION_UPDATED: {
        const message = getPatientUpdatedFields({
          diffs: event.context?.diff,
          translations,
        });

        const [eventMessageTitle, ...reasons] = message?.split("\n") || "";

        const infoExcludedProviderKey = eventMessageTitle
          ? translations.timeline.patientUpdated.infoExcludedProviders({
              reason: eventMessageTitle,
            })
          : translations.timeline.patientUpdated.infoExcludedProvidersNoReason;

        return {
          title: `${getTranslationForAccount(
            translations.timeline.patientUpdated,
            modularAccountName,
          )} ${infoExcludedProviderKey}`,
          ...(eventMessageTitle && {
            eventMessages: [{ title: eventMessageTitle, reasons }],
          }),
          icon: EditIcon,
        };
      }

      case EVENTS.REQUEST_STATUS_CHANGE: {
        return NO_EVENT;
      }

      case EVENTS.AUCTION_TO_BE_STARTED: {
        return {
          title: translations.timeline.auctionToBeStarted({
            accountName,
          }),
          icon: SendIcon,
        };
      }

      case EVENTS.DISMISSAL_CANCELLED: {
        return {
          title: translations.timeline.cancelRejection({
            accountName,
            careprovider: providerName,
          }),
          icon: UndoIcon,
        };
      }

      case EVENTS.AUCTION_PAUSED: {
        return {
          title: isInternalToolAccount
            ? translations.timeline.auctionPaused.automatic
            : getTranslationForAccount(
                translations.timeline.auctionPaused,
                modularAccountName,
              ),
          icon: PauseIcon,
        };
      }

      case EVENTS.AUCTION_STATUS_CHANGED: {
        const type = event.context?.new_status;
        switch (type) {
          case AUCTION_STATUS_PAUSED:
            return {
              title: isInternalToolAccount
                ? translations.timeline.auctionPaused.automatic
                : getTranslationForAccount(
                    translations.timeline.auctionPaused,
                    modularAccountName,
                  ),
              icon: PauseIcon,
            };
          default:
            return NO_EVENT;
        }
      }

      case EVENTS.USER_PASSWORD_CHANGED: {
        return {
          title: translations.acp.accountTimeline.passwordChange,
        };
      }

      case EVENTS.PATIENT_AUTOMATICALLY_ARCHIVED: {
        return {
          title: translations.responseEvents.searchArchivedAutomatically,
          icon: ArchiveIcon,
        };
      }

      case EVENTS.AUCTION_STARTED: {
        return {
          title: translations.timeline.auctionStarted({
            accountName,
          }),
        };
      }

      case EVENTS.AUCTION_SUCCESS: {
        const trasnferredToSpecialization =
          event.context?.transferred_to_specialization;
        if (trasnferredToSpecialization) {
          const specialization = getOntology({
            type: ONTOLOGY_SPECIALIZATIONS,
            key: trasnferredToSpecialization,
          });
          return {
            title: `${translations.patient.stopAuction.internalTransfer} - ${specialization}`,
          };
        }
        return {
          title: auctionSuccessEvent(
            event,
            translations,
            getOntology,
            auctionSuccessUnknownTranslation,
          ),
        };
      }

      case EVENTS.FILE_UPLOAD:
      case EVENTS.ELIGIBILITY_FILE_UPLOAD: {
        return {
          title: getTranslationForAccount(
            translations.requestEvents.sentaFile,
            modularAccountName,
          ),
          receiverTitle: getTranslationForAccount(
            translations.requestEvents.sentaFile,
            modularAccountName,
          ),
          icon: AttachFile,
          ...getFileNameConfig(event?.context?.file, translations),
        };
      }

      case EVENTS.FILE_SHARED: {
        if (
          dismissSealdAccessEvent({
            event,
            loggedCareproviderId,
            loggedCareseekerId,
          })
        ) {
          return NO_EVENT;
        }
        if (modularAccountName) {
          return {
            title: translations.timeline.filesChanged.fileSharedOtherAccount({
              accountName: modularAccountName,
            }),
            icon: AttachFile,
            eventMessages: [getFileLink({ translations })],
          };
        }

        const recipientName =
          event.careprovider?.name ?? event.careseeker?.name;
        if (recipientName) {
          return {
            title: translations.timeline.filesChanged.fileSharedMyAccount({
              name: recipientName,
            }),
            icon: AttachFile,
            eventMessages: [getFileLink({ translations })],
          };
        }

        return {
          title: getTranslationForAccount(
            translations.requestEvents.sentaFile,
            modularAccountName,
          ),
          icon: AttachFile,
          eventMessages: [getFileLink({ translations })],
        };
      }

      case EVENTS.FILE_UPLOADED: {
        if (modularAccountName) return NO_EVENT;
        return {
          title: translations.timeline.filesChanged.fileUploaded,
          icon: AttachFile,
        };
      }

      case EVENTS.FILE_SHARE_MODE_CHANGED: {
        if (modularAccountName)
          return {
            title:
              translations.timeline.filesChanged.fileAccessChangedOtherAccount({
                accountName: modularAccountName,
              }),
            icon: AttachFile,
          };
        return {
          title: translations.timeline.filesChanged.fileAccessChangedMyAccount({
            previousFileShareMode: getOntology({
              type: "fileShareMode",
              key: event.context?.previous_status,
            }),
            newFileShareMode: getOntology({
              type: "fileShareMode",
              key: event.context?.new_status,
            }),
          }),
          icon: AttachFile,
        };
      }

      case EVENTS.FILE_ACCESS_REVOKED: {
        if (
          dismissSealdAccessEvent({
            event,
            loggedCareproviderId,
            loggedCareseekerId,
          })
        ) {
          return NO_EVENT;
        }
        if (modularAccountName)
          return {
            title:
              translations.timeline.filesChanged.fileAccessRevokedOtherAccount({
                name: modularAccountName,
              }),
            icon: AttachFile,
          };
        const recipientName =
          event.careprovider?.name ?? event.careseeker?.name;
        if (recipientName)
          return {
            title:
              translations.timeline.filesChanged.fileAccessRevokedMyAccount({
                name: recipientName,
              }),
            icon: AttachFile,
          };
        return {
          title:
            translations.timeline.filesChanged
              .fileAccessRevokedMyAccountMessenger,
          icon: AttachFile,
        };
      }

      case EVENTS.FILE_DELETED: {
        if (modularAccountName)
          return {
            title: translations.timeline.filesChanged.fileDeletedOtherAccount({
              name: modularAccountName,
            }),
            icon: DeleteIcon,
          };
        return {
          title: translations.timeline.filesChanged.fileDeletedMyAccount,
          icon: DeleteIcon,
        };
      }

      case EVENTS.RESPONSE_APPROVED: {
        const message = getMessage(event, encrypted, translations);
        return {
          icon: CheckIcon,
          variant: "success",
          receiverTitle: translations.responseEvents.patientAttributedToYou,
          title: getTranslationForAccount(
            translations.requestEvents.validatedThisProvider,
            modularAccountName,
            providerName,
          ),
          ...(message && { messages: [message] }),
          ...(event.context?.winning_solution && {
            eventMessages: [
              {
                title: getOntology({
                  type: ONTOLOGY_SOLUTION,
                  key: event.context?.winning_solution,
                }),
              },
            ],
          }),
        };
      }

      case EVENTS.REQUEST_UNAVAILABLE:
      case EVENTS.RESPONSE_REJECTED: {
        return getRequestUnavailableOrRejected({
          encrypted,
          event,
          getOntology,
          location,
          modularAccountName,
          notNeededTranslation,
          providerName,
          translations,
          type: event.type,
        });
      }

      case EVENTS.REQUEST_DISMISSED: {
        return {
          title: translations.timeline.requestDismissed({
            accountName,
            careprovider: providerName,
          }),
          message: getMessage(event, encrypted, translations),
          icon: NotInterestedIcon,
          variant: "error",
        };
      }

      case EVENTS.MAIL_BOUNCED: {
        return {
          title: getBouncedEmailsTranslations(event, translations),
          icon: ErrorIcon,
        };
      }

      case EVENTS.REQUEST_AUTOMATICALLY_ARCHIVED: {
        return {
          title: translations.responseEvents.requestArchivedAutomatically,
          icon: ArchiveIcon,
        };
      }

      case EVENTS.REQUEST_AUTOMATICALLY_UNARCHIVED: {
        return {
          title: translations.responseEvents.requestArchivedManually.undo.tools,
          icon: UndoIcon,
        };
      }

      case EVENTS.REQUEST_MANUALLY_ARCHIVED: {
        return {
          title: isInternalToolAccount
            ? translations.responseEvents.requestArchivedManually.tools
            : getTranslationForAccount(
                translations.responseEvents.requestArchivedManually,
                modularAccountName,
              ),
          icon: ArchiveIcon,
        };
      }

      case EVENTS.REQUEST_MANUALLY_UNARCHIVED: {
        return {
          title: isInternalToolAccount
            ? translations.responseEvents.requestArchivedManually.undo.tools
            : getTranslationForAccount(
                translations.responseEvents.requestArchivedManually.undo,
                modularAccountName,
              ),
          icon: UndoIcon,
        };
      }

      case EVENTS.SHARE_PATIENT_INFORMATION: {
        return {
          icon: LockIcon,
          title: sharePatientDataAutomatically // still need to keep this for a while to backfill old events, until patients are old enough(redacted)
            ? translations.requestEvents.sharedPatient.automatic({
                providerName,
              })
            : getTranslationForAccount(
                translations.requestEvents.sharedPatient,
                modularAccountName,
                providerName,
              ),
        };
      }

      case EVENTS.SHARE_PATIENT_INFORMATION_AUTOMATICALLY: {
        return {
          icon: LockIcon,
          title: translations.requestEvents.sharedPatient.automatic({
            providerName,
          }),
        };
      }

      case EVENTS.ACCOUNT_ACTIVATION_EMAIL: {
        return {
          title: translations.acp.accountTimeline.activationEmailSent,
          ...(event?.context?.email && {
            eventMessages: [{ title: event.context.email }],
          }),
        };
      }

      case EVENTS.ACCOUNT_LOGIN: {
        return {
          title: translations.acp.accountTimeline.login,
        };
      }

      case EVENTS.ACCOUNT_CREATED: {
        return {
          title: translations.acp.accountTimeline.accountCreated,
        };
      }

      case EVENTS.ADMIN_SET_PASSWORD: {
        return {
          title: translations.acp.accountTimeline.passwordSetByAdmin,
        };
      }

      case EVENTS.PASSWORD_RESET_SUCCESS: {
        return {
          title: translations.acp.accountTimeline.passwordResetSuccessfully,
        };
      }

      case EVENTS.ADMIN_RESET_PASSWORD: {
        return {
          title: translations.acp.accountTimeline.resetPasswordRequestedByAdmin,
        };
      }

      case EVENTS.ACCOUNT_UPDATED: {
        return {
          title: translations.acp.accountTimeline.accountUpdated,
          ...(event?.context?.diff && {
            eventMessages: [{ title: event?.context?.diff?.join(", ") }],
          }),
        };
      }

      case EVENTS.USER_PASSWORD_RESET_EMAIL: {
        return {
          title: translations.acp.accountTimeline.resetPasswordRequestedByUser,
        };
      }

      case EVENTS.EMAIL_INVITATION: {
        return {
          title: translations.acp.invitationEmailSent({
            account: getName(event.subject_account, getOntology),
          }),
        };
      }

      case EVENTS.SENT_TO_CALLCENTER: {
        return {
          title: translations.timeline.pushedToCallcenter,
        };
      }

      case EVENTS.ONBOARDING_EMAIL_SENT: {
        return {
          title: translations.timeline.onboardingEmailSent,
          eventMessages: [
            { title: getOnboardingEmailType(event, translations) },
          ],
        };
      }

      case EVENTS.SMS_SENT: {
        return {
          title: translations.timeline.smsSent,
          icon: SmsIcon,
        };
      }

      case EVENTS.CARESEEKER_UPDATED: {
        return {
          title: translations.timeline.senderUpdated,
          ...(event?.context?.diff && {
            eventMessages: [{ title: event?.context?.diff?.join(", ") }],
          }),
        };
      }

      case EVENTS.CARESEEKER_CREATED: {
        return {
          title: translations.timeline.senderCreated,
        };
      }

      case EVENTS.CAREPROVIDER_UPDATED: {
        return {
          title: translations.timeline.careproviderUpdated,
          ...(event?.context?.diff && {
            eventMessages: [{ title: event?.context?.diff?.join(", ") }],
          }),
        };
      }

      case EVENTS.CAPACITY_UPDATED: {
        return {
          title: event?.context?.capacity_confirmed
            ? translations.timeline.capacityConfirmed
            : translations.timeline.capacityUpdated,
        };
      }

      case EVENTS.CAREPROVIDER_CREATED: {
        return {
          title: translations.timeline.careproviderCreated,
        };
      }

      case EVENTS.CAREPROVIDER_TEST_SENT: {
        return {
          title: translations.timeline.testSent,
        };
      }

      case EVENTS.PATIENT_ARCHIVED: {
        return {
          title: translations.timeline.patientArchived,
          icon: ArchiveIcon,
        };
      }

      case EVENTS.CAREPROVIDER_TEST_ANSWERED: {
        return {
          title: translations.timeline.testAnswered,
        };
      }

      case EVENTS.REHAB_FORM_CREATED: {
        return {
          title: translations.timeline.rehaFormCreated({
            formName: getFileName(event, translations),
            accountName,
          }),
          icon: AssignmentIcon,
        };
      }

      case EVENTS.REHAB_FORM_UPDATED: {
        return {
          title: translations.timeline.rehaFormUpdated({
            accountName,
            formName: getFileName(event, translations),
          }),
          icon: EditIcon,
        };
      }

      case EVENTS.REHAB_FORM_DELETED: {
        return {
          title: translations.timeline.rehaFormDeleted({
            accountName,
            formName: getFileName(event, translations),
          }),
          icon: DeleteIcon,
        };
      }

      case EVENTS.TRANSITIONAL_CARE_FORM_CREATED: {
        return {
          title: translations.timeline.transitionalCareCreated({
            accountName,
          }),
          icon: AssignmentIcon,
        };
      }

      case EVENTS.TRANSITIONAL_CARE_FORM_UPDATED: {
        return {
          title: translations.timeline.transitionalCareUpdated({
            accountName,
          }),
          icon: EditIcon,
        };
      }

      case EVENTS.TRANSITIONAL_CARE_FORM_DELETED: {
        return {
          title: translations.timeline.transitionalCareDeleted({
            accountName,
          }),
          icon: DeleteIcon,
        };
      }

      case EVENTS.REHAB_FORM_STATUS_CHANGED: {
        switch (event.context?.new_status) {
          case PATIENT_FORM_DRAFT: {
            return {
              title: translations.timeline.rehaFormUpdatedBackToDraft({
                accountName,
                formName: getFileName(event, translations),
              }),
              icon: UndoIcon,
            };
          }

          case PATIENT_FORM_READY: {
            return {
              title: translations.timeline.rehaFormUpdatedCompleted({
                accountName,
                formName: getFileName(event, translations),
              }),
              icon: DoneIcon,
            };
          }

          case PATIENT_FORM_ALREADY_SHARED: {
            return {
              title: translations.timeline.rehaFormUpdatedAlreadySent({
                accountName,
                formName: getFileName(event, translations),
              }),
              icon: AssignmentIcon,
              message: event.context?.message,
            };
          }
        }
        break;
      }

      case EVENTS.ELIGIBILITY_STATUS_CHANGED: {
        switch (event.context?.new_status) {
          case PATIENT_ELIGIBILITY_TO_BE_PROCESSED:
            return {
              title: translations.timeline.eligibilityBackToNotProcessed({
                accountName,
              }),
              icon: UndoIcon,
            };
          case PATIENT_ELIGIBILITY_APPROVED: {
            return {
              title: translations.timeline.eligibilityApproved({
                accountName,
              }),
              icon: DoneIcon,
            };
          }

          case PATIENT_ELIGIBILITY_IN_PROCESS: {
            switch (event.context?.previous_status) {
              case PATIENT_ELIGIBILITY_TO_BE_PROCESSED:
                return {
                  title: translations.timeline.eligibilityInProcess({
                    accountName,
                  }),
                  icon: SendIcon,
                };
              default:
                return {
                  title: translations.timeline.eligibilityCancelled({
                    accountName,
                  }),
                  message: getMessage(event, encrypted, translations),
                  icon: UndoIcon,
                };
            }
          }

          case PATIENT_ELIGIBILITY_REJECTED: {
            const rejectReason = getMessage(event, encrypted, translations);
            return {
              title: translations.timeline.eligibilityRejected({
                accountName,
              }),
              message: `${getOntology({
                type: "eligibilityRejectReason",
                key: event.context?.eligibility_reject_reason,
              })}${
                rejectReason
                  ? `${translations.general.colon} ${getMessage(
                      event,
                      encrypted,
                      translations,
                    )}`
                  : ""
              }`,
              icon: CloseIcon,
            };
          }
        }
        break;
      }

      case EVENTS.COMPANY_CREATED: {
        return {
          title: translations.timeline.companyCreated,
        };
      }

      case EVENTS.COMPANY_UPDATED: {
        return {
          title: translations.timeline.companyUpdated,
          ...(event?.context?.diff && {
            eventMessages: [{ title: event?.context?.diff?.join(", ") }],
          }),
        };
      }

      case EVENTS.COMPANY_STATUS_CHANGED: {
        return {
          title: translations.timeline.companyStatusChange({
            newStatus: getOntology({
              type: "companyStatus",
              key: event.context?.new_status || -1,
            }),
          }),
        };
      }

      case EVENTS.COMPANY_RESOURCE_ADDED: {
        return {
          title: translations.timeline.companyResourceAdded({
            resource:
              event.careprovider?.name ||
              event.careseeker?.name ||
              "unknown resource",
          }),
        };
      }

      case EVENTS.COMPANY_RESOURCE_REMOVED: {
        return {
          title: translations.timeline.companyResourceRemoved({
            resource:
              event.careprovider?.name ||
              event.careseeker?.name ||
              "unknown resource",
          }),
        };
      }

      case EVENTS.SOCIAL_WORKER_ASSIGNEE_CHANGED: {
        return {
          title: translations.timeline.assigneeChanged({
            newAssigneeName,
            oldAssigneeName,
          }),
          icon: PersonIcon,
        };
      }

      case EVENTS.INSURANCE_ASSIGNEE_CHANGED: {
        return {
          title: translations.timeline.assigneeChanged({
            newAssigneeName,
            oldAssigneeName,
          }),
          icon: PersonIcon,
        };
      }

      case EVENTS.PATIENT_MISSING_INFO: {
        return {
          title: getTranslationForAccount(
            translations.requestEvents.requestedPatientData,
            modularAccountName,
          ),
          message: event?.context?.message,
        };
      }

      case EVENTS.RECEIVER_MARKED_PREFERRED: {
        return {
          title: getTranslationForAccount(
            translations.timeline.markedPatientPreferred,
            modularAccountName,
            providerName,
            {
              receiverTypeSingularPreferred: getOntology({
                type: "receiverTypeSingularPreferred",
                key: event?.context?.patient_type,
              }),
            },
          ),
          message: null,
          icon: StarIcon,
        };
      }

      case EVENTS.RECEIVER_UNMARKED_PREFERRED: {
        return {
          title: getTranslationForAccount(
            translations.timeline.removedPatientPreferred,
            modularAccountName,
            providerName,
            {
              receiverTypeSingularPreferred: getOntology({
                type: "receiverTypeSingularPreferred",
                key: event?.context?.patient_type,
              }),
            },
          ),
          message: null,
          icon: StarBorderIcon,
        };
      }

      case EVENTS.RECEIVER_EDITED_PREFERRED_REASON: {
        return {
          title: getTranslationForAccount(
            translations.timeline.addedPatientWishReason,
            modularAccountName,
          ),
          eventMessages: [getPatientWishReasonMessage(event, translations)],
          icon: !event.context?.message ? StarBorderIcon : StarIcon,
        };
      }

      case EVENTS.AUCTION_RESET:
      case EVENTS.CAREPROVIDER_STATUS_CHANGED:
      case EVENTS.CAREPROVIDER_ONBOARDING_STATUS_CHANGED:
      case EVENTS.CARESEEKER_STATUS_CHANGED:
      case EVENTS.ACCOUNT_PERMISSIONS:
      case EVENTS.PASSWORD_RESET_VERIFY:
      case EVENTS.REQUEST_COST_COVERAGE_UPDATED:
      case EVENTS.ACCOUNT_STATUS_CHANGED:
      case EVENTS.EMAIL_SENT:
      case EVENTS.WAITING_FOR_ELIGIBILITY:
      case EVENTS.FAX_ATTEMPTED:
      case EVENTS.SESSION_KEY_CREATED:
      case EVENTS.SESSION_KEY_REVOKED:
      case EVENTS.PENDING_SESSION_KEY_CREATED:
      case EVENTS.COST_PAYER_CHANGED:
      case EVENTS.SEALD_ENCRYPTION_CREATED:
      case EVENTS.BROWSER_NOTIFICATION_SENT:
      case EVENTS.CONSULTANT_CREATED:
      case EVENTS.CONSULTANT_UPDATED:
      case EVENTS.CONSULTANT_FILE_CREATED:
      case EVENTS.CONSULTANT_FILE_DELETED:
      case EVENTS.CONSULTANT_DELETED:
      case EVENTS.AUCTION_ELECTIVE_FLOW: {
        return NO_EVENT;
      }

      default: {
        // This is to guarantee that all events are covered
        const exhaustiveCheck: never = type;
        return exhaustiveCheck;
      }
    }
  }

  const specs = getSpecs(event.type as EventType);

  if (specs === NO_EVENT) {
    console.info(
      `Event type ${event.type} is returned as undefined purposefully`,
    );
    return undefined;
  }
  if (!specs?.title || specs.title === NO_TITLE) {
    console.groupCollapsed(`Event type ${event.type || ""} is not supported`);
    console.error(
      `Event type ${event.type || ""} is not supported ${safeLogEvent(event)}`,
    );
    console.groupEnd();
    return undefined;
  }

  if (specs.shouldHide?.includes(location)) {
    return undefined;
  }

  specs.sentByLabel = whoIsSender(accountId, event, translations);

  return specs;
}
